import { React, useState } from "react";
import SlidebarPlanner from "./SlidebarPlanner";

function Slidebar({ selectedDates, onDrop, style }) {
  const [title, setTitle] = useState("Planner");
  const [isEditing, setIsEditing] = useState(false);
  const [isInfoWindowVisible, setIsInfoWindowVisible] = useState(false);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "kypl",
        url: "./plan",
      });
    } else {
      alert("공유하기가 지원되지 않는 환경 입니다.");
    }
  };

  const handleDrop = (updatedLists) => {
    onDrop(updatedLists);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTitleBlur = () => {
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false);
    }
  };

  const handleInfoClick = () => {
    setIsInfoWindowVisible(true);
  };

  const handleCloseInfoWindow = () => {
    setIsInfoWindowVisible(false);
  };

  return (
    <div
      className="font-sans bg-gray-200 w-full shadow-md border pt-16 rounded-r-xl md:pt-0"
      style={style}
    >
      <div className="flex justify-center text-2xl font-bold mb-6 text-gray-800">
        <div className="mr-2 w-8 h-0"></div>

        <div className="relative text-2xl pt-6 pb-4 flex items-center justify-center">
          {isEditing ? (
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              onKeyDown={handleKeyDown}
              autoFocus
              className="text-2xl font-bold text-center bg-gray-200 h-10"
            />
          ) : (
            <span className="text-2xl font-bold">{title}</span>
          )}
          <button
            onClick={handleEditClick}
            className="ml-2 mt-2 text-sm text-black hover:text-blue-600 transition"
          >
            <i className="fas fa-edit w-8 h-0 text-xl"></i>
          </button>

          <button
            onClick={handleInfoClick}
            className="absolute -right-20 w-8 h-8 bg-gray-300 text-gray-700 rounded-full flex items-center justify-center shadow hover:bg-gray-400 transition"
          >
            ?
          </button>
        </div>
      </div>
      <SlidebarPlanner selectedDates={selectedDates} onDrop={handleDrop} />
      <div className="flex justify-center items-center h-16">
        <button
          className="w-1/2 h-full bg-blue-500 text-white rounded-lg flex items-center justify-center shadow hover:bg-blue-600 transition"
          onClick={handleShare}
        >
          저장하기
        </button>
      </div>
      {isInfoWindowVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full sm:w-3/4 md:w-1/2 lg:w-1/3 mx-4">
            <h2 className="text-xl font-bold mb-4">플래너 사용방법</h2>
            <p className="mb-4">
              <h2 className="text-2xl font-bold mb-6 text-gray-800">
                플래너 사용방법
              </h2>
              <div className="text-l text-gray-700">
                <p className="mb-6">
                  <span className="font-bold">
                    1. 여행하려는 일자를 선택하세요.(필수)
                  </span>
                  <br />
                  <br />
                  <span className="font-bold">2. 예산을 설정하세요.</span>
                  <br />- 각 일자별로 예산 설정이 가능합니다.
                  <br />- 설정을 완료하면 바로 옆에 플래너가 일자별로
                  생성됩니다.
                  <br />
                  <br />
                  <span className="font-bold">
                    3. 원하는 업장을 찾아보세요.
                  </span>
                  <br />
                  - 상단 검색란에서 직접 검색할 수도 있고, 하단 리스트에 있는
                  업소를 선택할 수 있습니다.
                  <br />-{" "}
                  <span className="font-bold">착한업소 ON/OFF 기능</span>을
                  통해서 착한 업소만 확인할 수 있습니다.
                  <br />
                  <br />
                  <span className="font-bold">
                    4. 선택한 업장을 드래그(강조)하여 플래너란에 놓아주세요.
                  </span>
                  <br />- 원하시는 일자에 해당되는 칸에 넣으셔야 제대로
                  적용됩니다.
                  <br />
                  <br />
                  <span className="font-bold">5. 컬러를 선택해주세요.</span>
                  <br />- 다른 여행계획과 혼동하지 않기 위해서 원하는 색상을
                  적용할 수 있습니다.
                  <br />
                  <br />
                  <span className="font-bold">
                    6. 지도란에는 선택한 여행 계획의 루트가 보입니다.
                  </span>
                </p>
              </div>
            </p>
            <button
              onClick={handleCloseInfoWindow}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Slidebar;
